import React from "react"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { Link } from "gatsby"
import gql from "graphql-tag"
import { listCharacters } from "../graphql/queries"
import { deleteCharacter } from "../graphql/mutations"
import SEO from "../components/seo"
import { withLayout } from "../components/authenticated-layout"
import CharacterList from "../components/character-list"
import ErrorPanel from "../components/error-panel"

const GET_PAGE_DATA = gql(listCharacters)
const DESTROY_CHARACTER = gql(deleteCharacter)

export function Characters() {
  const { data, loading, error, refetch } = useQuery(GET_PAGE_DATA)
  const [
    deleteCharacter,
    { error: submitError, loading: submitting },
  ] = useMutation(DESTROY_CHARACTER, {
    refetchQueries: [{ query: GET_PAGE_DATA }],
  })

  if (loading) return "Loading..."

  if (error) {
    return <ErrorPanel error={error} onDismiss={refetch} />
  }

  return (
    <>
      <SEO title="Character List" />
      <div className="md:mx-auto max-w-md">
        <div className="my-4 flex justify-between items-center">
          <div className="text-xl text-teal-500">Crew Manifest</div>
          <Link
            className="border rounded p-2 w-32 text-center text-white hover:text-black hover:bg-white"
            to="/create-character"
          >
            Add Employee
          </Link>
        </div>

        <ErrorPanel error={submitError} />

        <CharacterList
          data={data}
          deleteCharacter={deleteCharacter}
          submitting={submitting}
        />
      </div>
    </>
  )
}

export default withLayout(Characters)
