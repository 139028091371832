import Amplify from "aws-amplify"
import awsConfig from "./aws-exports"
import ApolloClient from "apollo-boost"

Amplify.configure(awsConfig)
export default async function createClient() {
  const session = await Amplify.Auth.currentSession()
  const token = session.getAccessToken().getJwtToken()

  return (window.__APOLLO_CLIENT__ = new ApolloClient({
    uri: awsConfig["aws_appsync_graphqlEndpoint"],
    headers: {
      authorization: token,
    },
  }))
}
