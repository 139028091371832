import React, { useEffect, useState } from "react"
import { withAuthenticator } from "aws-amplify-react"
import { ApolloProvider as AppSyncApolloProvider } from "react-apollo"
import { ApolloProvider as HookApolloProvider } from "@apollo/react-hooks"
import createClient from "../apollo-client"
import ErrorBoundary from "./error-boundary"
import Layout from "./layout"

const AuthenticatedLayout = withAuthenticator(
  ({ children, ...rest }) => {
    const [client, setClient] = useState()

    useEffect(() => {
      createClient().then(setClient)
    }, [])

    if (!client) return <span>Loading...</span>

    return (
      <Layout {...rest}>
        <AppSyncApolloProvider client={client}>
          <HookApolloProvider client={client}>
            <ErrorBoundary>{children}</ErrorBoundary>
          </HookApolloProvider>
        </AppSyncApolloProvider>
      </Layout>
    )
  },
  { includeGreetings: false }
)

export default AuthenticatedLayout

export const withLayout = Component => props => (
  <AuthenticatedLayout>
    <Component {...props} />
  </AuthenticatedLayout>
)
