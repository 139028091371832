// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:303b9bf6-af59-4cfd-a94a-46b35677d0c3",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ecyAR63vn",
    "aws_user_pools_web_client_id": "5qdmrah6qoohd7dgrm4qvah4bb",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://kb5h4daxdfcn3o2waiaelgvg4i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "unitystationorg-20190925002049-hostingbucket-leebo",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://unitystationorg-20190925002049-hostingbucket-leebo.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
