import React from "react"
import { Link } from "gatsby"

export default function CharacterList({ data, deleteCharacter, submitting }) {
  const { items: characters } = data.listCharacters

  return (
    <>
      <ul>
        {(characters.length > 0 &&
          characters.map(item => (
            <li
              key={item.id}
              className="flex items-center bg-gray-700 my-8 px-4 rounded-lg"
            >
              <div className="-ml-4 mr-4 p-6 bg-gray-600 border rounded-lg">
                <i className="fas fa-user" />
              </div>
              <div className="relative flex-1">
                <div>{item.name}</div>
                <div className="opacity-75">{item.role}</div>
                <div className="absolute right-0 bottom-0 -mb-6">
                  <button
                    type="button"
                    className="ml-4 px-2 border border-red-900 text-red-900 bg-red-500 rounded-full hover:bg-red-700"
                    disabled={submitting}
                    onClick={() => {
                      const input = { id: item.id }
                      deleteCharacter({ variables: { input } })
                    }}
                  >
                    Terminate
                  </button>
                </div>
              </div>
            </li>
          ))) || (
          <p>
            You have no characters. Maybe you should{" "}
            <Link to="/create-character">create</Link> one?
          </p>
        )}
      </ul>
    </>
  )
}
